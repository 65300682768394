body {
    background: white;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav-link a {
    color: #666;
}

.nav-link a:hover {
    color: #49c5b6;
    text-decoration: none;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #49c5b6;
    text-decoration: none;
}

.btn-thin {
    color:  #666;
}

.btn-primary {
    background:  #49c5b6;
    border-color: #49c5b6;
}

.btn-primary {
    background:  #49c5b6;
    border-color: #49c5b6;
}

.btn-primary {
    background:  #49c5b6;
    border-color: #49c5b6;
}

.btn-primary:disabled, .btn-primary:disabled:hover {
    background: #6c757d;
    border-color: #6c757d;
}


.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    background-color: #338c81;
    border-color: #338c81; /*set the color you want here*/
}

.btn-outline-primary {
    color: #49c5b6;
    border-color: #49c5b6;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-outline-primary {
    background-color: #338c81;
    border-color: #338c81; /*set the color you want here*/
}

.btn-secondary {
    background:  #8c979e;
    border-color: #8c979e;
}

.btn-outline-secondary {
    color: #8c979e;
    border-color: #8c979e;
}

.btn-outline-link {
    color: #49c5b6;
}

.btn-outline-link:hover, .btn-outline-link:focus, .btn-outline-link:active, .btn-outline-link.active, .open>.dropdown-toggle.btn-outline-link {
    color: #338c81;
}

.btn-circle { 
    width: 60px; 
    height: 60px; 
    padding: 7px 10px; 
    border-radius: 30px; 
    text-align: center;
} 

.LoginPage, .SignUpPage, .PasswordForgetPage {
    max-width: 500px;
}

.footerLink {
    color: #666;
}

.footerLink:hover {
    color: #49c5b6;
    text-decoration: none;
}

.text-primary {
    color: #49c5b6!important;
}

a {
    color: #49c5b6;
}

a:hover, a:focus, a:active, a.active {
    color: #338c81;
}

.pageContent {
    margin-top: 2rem;
    margin-bottom: 3rem;
}


/* img.network-logo {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: white;
    padding: 2px;
    margin: 2px;
} */

div.screenDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    min-height: 5rem;
}

.alert-primary {
    background-color: transparent;
    border-color: #49c5b6;
    color: #49c5b6;
}